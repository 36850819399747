<template>
    <div class="blankPage">
        <div class="minPage">
            <div>
                <img src="@/assets/image/header/404.gif" alt="" />
                <p><el-button @click="$router.push('/')" round>返回首頁</el-button></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.blankPage {
    margin: 20px 0 0px;

    .minPage {
        margin: auto;
        min-height: 650px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        img{
            width: inherit;
            height: inherit;
            margin-bottom: 20px;
        }
    }


}
</style>
